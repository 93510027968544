import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt";

export default class extends Controller {
  static targets = ["form", "datepicker"];

  connect() {
    this.dateInput = flatpickr(this.datepickerTarget, {
      locale: Portuguese,
      altInput: true,
      altFormat: "d F Y",
      altInputClass: "border-0 p-0 text-2xl font-bold leading-none",
      onValueUpdate: () => {
        this.reloadPage();
      }
    });
  }

  openCalendar() {
    this.dateInput.open();
  }

  reloadPage() {
    this.formTarget.requestSubmit();
  }
}
